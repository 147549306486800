let en = {
  translations: {
    "Birthday omen": "Birthday omen",
    Horoscope: "Horoscope",
    Palm: "Palm",
    Coffee: "Coffee",
    Tarot: "Tarot",
    "Animal Spirit": "Animal Spirit",
    WhichAnimal: "WHICH ANIMAL ARE YOU?",
    errorOccured: "An error occured. Please try again.",
    myFortune: "Tell me my fortune",
    Animal: "Animal",
    Loading: "Loading...",
    Uploading: "Uploading...",
    CoffeeCupGuide: "Coffee cup reading guideline",
    CoffeeDesc: `Turn the cup over upside-down on the saucer. think about your
                intent and start with take a photo of inside your coffee cup`,
    isNotCup: "The image is not a cup!",
    Start: "Start",
    Upload: "Upload",
    CancelCamera: "Cancel Camera",
    CameraPermission: "Camera permission is denied.",
    AllowPermission: "Please allow permission or upload your photo",
    ChooseBirthday: "Choose the Birthday you want:",
    BestFortune: "Best Fortune Teller",
    StepAwayFortune: "Only a step away from your fortune",
    ContinueGoogle: "Continue with Google",
    FindSecrets: "Find the secrets in your hand",
    isNotPalm: "The image is not a Palm Hand!",
    ScanHand: `By advanced scanning and reading technology, Palm Reader can
               predict your fortune, love, life and many more aspects.`,
    TakePicture: "Take picture",
    DeniedCamera:
      "Camera Permission is denied or You can't use camera on this device",
    PalmReading: "Palm reading",
    Birthday: "Birthday",
    YourIntention: "set your intention, focus on it and let's start",
    AboutUs: `Portutel has an expert team of mobile app and game developers who can
          transform ideas into reality, rendering outstanding solutions in the
          form of apps. We offer flexible engagement models and deliver
          domain-specific solutions that are aligned with industry trends while
          meeting requirements, ensuring that our services in different
          categories meet the needs of users.`,
    AboutUsExtra: `Portutel has been established with the aim of developing,
              publishing and promoting the exciting mobile apps & games in
              various categories by considering the specific characteristics of
              each marketplace. Our central office is located in Portugal with
              focus on local and global market. We have partnered with reputable
              marketing companies and mobile operators to introduce world-class
              mobile applications and to have a stronger presence in emerging
              markets.`,
    AboutUsTitle: "About Us",
    More: "More",
    Less: "Less",
    PrivacyTitle: "Privacy",
    Privacy: `Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.
    Best Fortune Teller may edit reviews and comments sent by users in accordance with the rules of the website. 
    Also, if the user's comment or message is subject to instances of illegal content, Best Fortune Teller can use the recorded information to pursue legal action. 
    Best Fortune Teller does not transfer the user's personal information to any other person or organization unless it's required by law to provide it to the competent authorities.`,
    PrivacyExtra: `Best Fortune Teller , like other websites, uses IP collection and cookies, but the protocol, server, and security layers of Best Fortune Teller and data management practices protect users' information and prevent unauthorized access. Best Fortune Teller does its best to protect and maintain the information and privacy of its users and hopes to provide a safe, comfortable, and enjoyable experience for all users. Users are able to share their problems, criticisms and suggestions by emailing Best Fortune bestfortuneteller@portutel.com`,
    TermsTitle: "Terms & Conditions",
    TermsCondition: `Do you like to know your fortune? The art of divination is important
          in our everyday life whether we recognize it or not. With Best Fortune
          Teller you are exactly where you should be! In this application, you
          can see what the universe has for you for the day, week or month.`,
    TermsConditionExtra: `None of us can indeed change the past nor the future but we can
              use the information here to know where we are heading to! In this
              application you can get your daily horoscope, spirit animal,
              birthday omen, Tarot, Coffee Cup reading and your Palm reading.
              Please note that the purpose of the service is entertaining only.
              In order to use the service users must subscribe. After
              subscribing to Best Fortune Teller, the user can use all features
              for the duration of the subscription. The
              service provider has no impact on users and operators who cannot
              ensure that the information exchanged by users and operators is
              correct. The service provider tries to provide the maximum
              possible availability of the service. However, due to the
              technical properties of the Internet connection and mobile
              telephony, over which the service provider has no influence, data
              transmission may occasionally be interrupted. To use the service,
              the user must use a mobile phone that is working and has a valid
              connection (signal) on their mobile phone. Without these
              conditions it is not possible to use the service.`,
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    Logout: 'Log Out',
    Login: 'Login',
    Unsubscribe: "Unsubscribe",
    UnsubSuccess: "You have been unsubscribed successfully",
    UnsubFail: "Fail to unsubscribe",
    Subscribe: "Subscribe",
    Confirm: 'Confirm',
    notSubscribed: "You do not have subscription ?",
    resendOtp: "Resend pin code",
    selectRegion: 'Select your region:'
  },
};

export default en;
